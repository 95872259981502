import React, { useEffect, useState } from 'react'
import { estimatorItems } from 'utils/firebase.util'
import EstimatorItem from './EstimatorItem'
import { Close } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { TextField, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ActionButton from './ActionButton'
import { storeEstimatorItems, loadEstimatorItems } from '../utils/app.util'

const useStyles = makeStyles({
  separator: {
    marginTop: '1em'
  },
  inlineButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})

export default function MyModal (props) {
  const styles = useStyles()
  const { showModal, setShowModal } = props
  // const [estItems, setEstItems] = useState([])
  const [categories, setCategories] = useState([])
  const [catItems, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const [selectedItems, setSelectedItems] = useState({})

  useEffect(() => {
    async function getEstimatorItems () {
      const items = await estimatorItems()
      const catsTmp = []
      items.forEach((el, index) => {
        el.items = el.items.map((item) => {
          return { ...item, category: el.name, numberOfItems: 0, result: 0 }
        })
        catsTmp.push({ title: el.name, value: index, items: el.items })
      })
      // setEstItems(items)
      setCategories(catsTmp)
    }
    getEstimatorItems() // from BE

    // check for locally stored selected items
    const storedItems = loadEstimatorItems()
    if (storedItems) setSelectedItems(storedItems)
  }, [])

  const addSelectedItem = () => {
    if (!selectedItem) return
    const tmp = { ...selectedItems }
    if (Object.keys(tmp).includes(selectedItem.category)) {
      tmp[selectedItem.category].items.push(selectedItem)
    } else {
      tmp[selectedItem.category] = { items: [selectedItem] }
    }
    setSelectedItems(tmp)
    storeEstimatorItems(tmp)
    setSelectedItem(null)
  }

  const onCategoryChange = (e, val) => {
    if (val === null) return
    setItems(val.items)
  }

  const onItemChange = (e, val) => {
    if (val === null) return
    setSelectedItem(val)
  }

  const onRemoveItem = (item, index) => {
    const tmpSel = { ...selectedItems }
    tmpSel[item.category].items.splice(index, 1)
    if (tmpSel[item.category].items.length === 0) {
      delete tmpSel[item.category]
    }
    setSelectedItems(tmpSel)
  }

  return (
    <div
      className={showModal ? 'modal-dialog show' : 'modal-dialog'}
    >
      <div className="modal-content">
        <div className="modal-header">
          <IconButton
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowModal(false)
            }}
            size='small'
          >
            <Close />
          </IconButton>
          <h4 className="modal-title" id="myModalLabel">
            The Muva Estimator
          </h4>
        </div>
        <div className="modal-body">
          <Autocomplete
            id="categories" options={categories}
            clearOnBlur
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <TextField {...params} label="Select a Category" variant="outlined" />}
            onChange={onCategoryChange}
          />
          {catItems.length > 0 &&
          <Autocomplete
            id="cat-items" options={catItems}
            className={styles.separator}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Select an item" variant="outlined" />}
            onChange={onItemChange}
            value={null}
            defaultValue={selectedItem}
          />
          }
          <div className={styles.separator}></div>
          <div className={styles.inlineButtons}>
            <ActionButton onClick={addSelectedItem} disabled={catItems && selectedItem === undefined}>
              Add Item
            </ActionButton>
          </div>
          <div className={styles.separator}></div>
          {Object.keys(selectedItems).length > 0 && Object.keys(selectedItems).map((el, index) => (
            <div key={`${el}_${index}`}>
              <h5>{el}</h5>
              <EstimatorItem items={selectedItems[el].items} onRemove={onRemoveItem} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
