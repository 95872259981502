import moment from 'moment'
import 'moment-timezone'
import queryString from 'query-string'
import ls from 'localstorage-slim'

export const userAgentIsMobile = () => {
  if (typeof window !== 'undefined' && navigator && (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i))) {
    return true
  }

  return false
}

export const getURLParam = (name) => {
  let param = null
  if (typeof window !== 'undefined' && window && window.location) {
    param = queryString.parse(window.location.search)[name]
  }
  return param
}

export const URLParams = () => (
  typeof window !== 'undefined' && window && window.location
    ? queryString.parse(window.location.search)
    : {}
)

export const reload = () => {
  if (typeof window !== 'undefined' && window.location) {
    window.location.reload()
  }
}

export const time =
  (input = Date.now(), timezone = 'America/Los_Angeles') => {
  // input normalization for Moment
  // const arg = input instanceof Timestamp ? input.toMillis() : input;
  // convert to moment
    return moment.tz(input, timezone)
  }

export const capitalize = (str) => {
  return str.replace(/\b\w/g, l => l.toUpperCase())
}

export const $tr = (centsIn, centsMin) => {
  if (typeof centsIn === 'undefined') return null
  const cents = Number(centsIn)

  if (typeof centsMin !== 'undefined' &&
      (cents <= Number(centsMin))) { return '$-' }

  return (
    (cents % 100) === 0
      ? `$${cents / 100}`
      : `$${(cents / 100).toFixed(2)}`
  )
}

const { linkID } = URLParams()

// TODO: remove orderID from interface
export const storeInput = (orderID, data) => {
  ls.set(`muva-${linkID}`, data, { ttl: moment.duration(6, 'months').seconds })
}

export const storeEstimatorItems = (items) => {
  ls.set(`muva-estimator-${linkID}`, items, { ttl: moment.duration(6, 'months').seconds })
}

export const loadEstimatorItems = () => {
  return ls.get(`muva-estimator-${linkID}`)
}

export const loadInput = (orderID) => {
  return ls.get(`muva-${linkID}`)
}

export const clearStoredInput = (orderID) => {
  return ls.remove(`muva-${linkID}`)
}

// States:

export const AdminState = {
  init: 'init',
  new: 'new',
  review: 'review',
  booked: 'booked',
  ongoing: 'ongoing',
  completed: 'completed',
  adminRejected: 'adminRejected',
  canceled: 'canceled'
}
export const AssignState = {
  assigned: 'assigned',
  providerExpired: 'providerExpired',
  providerRejected: 'providerRejected',
  quoted: 'quoted',
  customerExpired: 'customerExpired',
  customerRejected: 'customerRejected',
  booked: 'booked',
  canceled: 'canceled'
}
export const ServiceState = {
  new: 'new',
  booked: 'booked',
  canceled: 'canceled',
  reserved: 'reserved',
  reserveReview: 'reservedReview',
  authFailed: 'authFailed',
  authorized: 'authorized',
  introTerm: 'introTerm',
  recurTerm: 'recurTerm',
  awaitInvoice: 'awaitInvoice',
  invoiced: 'invoiced',
  paymentFailed: 'paymentFailed',
  addCharge: 'addCharge',
  completed: 'completed'
}
export const QuoteState = {
  INIT: 'INIT',
  // newly created
  NEW: 'NEW',
  // rejected by admin
  ADMIN_REJECTED: 'ADMIN_REJECTED',
  // in review for quote generation by mover
  MOVER_REVIEW: 'MOVER_REVIEW',
  // rejected by mover
  MOVER_REJECTED: 'MOVER_REJECTED',
  // mover did not respond in time limit
  MOVER_EXPIRED: 'MOVER_EXPIRED',
  // in review by customer
  CUSTOMER_REVIEW: 'CUSTOMER_REVIEW',
  // quote rejected by customer
  CUSTOMER_REJECTED: 'CUSTOMER_REJECTED',
  // quote expired for customer
  CUSTOMER_EXPIRED: 'CUSTOMER_EXPIRED',
  // quote accepted by customer
  CUSTOMER_ACCEPTED: 'CUSTOMER_ACCEPTED',
  // quote accepted by customer, move will happen
  BOOKED: 'BOOKED',
  // payment has been authorized 4 days in advance
  AUTHORIZED: 'AUTHORIZED',
  // payment authorization failed, will be canceld if not reckified
  AUTHORIZE_FAILED: 'AUTHORIZE_FAILED',
  // canceled after move booked (final state)
  CANCELED: 'CANCELED',
  NEEDS_INVOICE: 'NEEDS_INVOICE',
  // mover submitted invoice
  INVOICED: 'INVOICED',
  // payment declined by swipe
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  // stripe payment failed and additional charges exist
  ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE: 'ADDITIONAL_CHARGE_AND_PAYMENT_FAILURE',
  // stripe complete, additional charges present
  ADDITIONAL_CHARGE: 'ADDITIONAL_CHARGE',
  COMPLETED: 'COMPLETED'
}
